<template>
  <div id="login">
    <div class="form">
      <a-form-model ref="form" :model="model" :rules="validatorRules">
        <a-form-model-item required prop="username">
          <a-input
            v-model="model.username"
            size="large"
            placeholder="请输入帐户名"
          >
            <a-icon
              slot="prefix"
              type="user"
              :style="{ color: 'rgba(0,0,0,.25)' }"
            />
          </a-input>
        </a-form-model-item>
        <a-form-model-item required prop="password">
          <a-input
            v-model="model.password"
            size="large"
            type="password"
            autocomplete="false"
            placeholder="请输入密码"
          >
            <a-icon
              slot="prefix"
              type="lock"
              :style="{ color: 'rgba(0,0,0,.25)' }"
            />
          </a-input>
        </a-form-model-item>

        <a-row :gutter="0">
          <a-col :span="16">
            <a-form-model-item required prop="inputCode">
              <a-input
                v-model="model.inputCode"
                size="large"
                type="text"
                placeholder="请输入验证码"
              >
                <a-icon
                  slot="prefix"
                  type="smile"
                  :style="{ color: 'rgba(0,0,0,.25)' }"
                />
              </a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8" style="text-align: right">
            <!-- <img v-if="requestCodeSuccess" style="margin-top: 2px;" :src="randCodeImage" @click="handleChangeCheckCode"/> -->
            <!-- <img v-else style="margin-top: 2px;" src="../../assets/checkcode.png" @click="handleChangeCheckCode"/> -->
          </a-col>
        </a-row>
      </a-form-model>
    </div>
  </div>
</template>
<script>
import drawMixin from "../utils/drawMixin";
export default {
  mixins: [drawMixin],
  data() {
    return {
      model: {},
      validatorRules: {},
    };
  },
};
</script>
<style scoped>
#login {
  width: 100%;
  height: 100%;
  /* background: aqua; */
  background-image: url("../assets/imgs/bg5.png");
  background-size: cover;
  background-position: center center;
  position: relative;
}
.form {
  position: absolute;
  width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
