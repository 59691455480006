// 屏幕适配 mixin 函数

// * 默认缩放值
const scale = {
  width: '1',
  height: '1',
}

// * 设计稿尺寸（px）
const baseWidth = 4472
const baseHeight = 1376

// * 需保持的比例（默认1.77778）
// const baseProportion = parseFloat((baseWidth / baseHeight).toFixed(5))

export default {
  data() {
    return {
      // * 定时函数
      drawTiming: null
    }
  },
  mounted() {
    this.calcRate()
    window.addEventListener('resize', this.resize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    calcRate() {
      const appRef = this.$refs["appRef"]
      if (!appRef) return
      // 当前宽高比
      // const currentRate = parseFloat((window.innerWidth / window.innerHeight).toFixed(5))
      if (appRef) {
        scale.height = (window.innerHeight / baseHeight).toFixed(5)
        scale.width = (window.innerWidth / baseWidth).toFixed(5)
        appRef.style.transform = `scale(${scale.width}, ${scale.height}) translate(-50%, -50%)`
        // if (currentRate > baseProportion) {
        //   // 表示更宽
        //   scale.width = ((window.innerHeight * baseProportion) / baseWidth).toFixed(5)
        //   scale.height = (window.innerHeight / baseHeight).toFixed(5)
        //   console.log('1width', scale.width, 'height', scale.height);
        //   appRef.style.transform = `scale(${scale.width}, ${scale.height}) translate(-50%, -50%)`
        // } else {
        //   // 表示更高
        //   scale.height = ((window.innerWidth / baseProportion) / baseHeight).toFixed(5)
        //   //屏幕宽度/（设计图宽/设计图高）/设计图高=屏幕宽度是设计图尺寸的几倍 如：设计图宽3屏幕款12该计算为4
        //   scale.width = (window.innerWidth / baseWidth).toFixed(5)
        //   //屏幕宽/设计图宽 如：设计图宽3屏幕款12该计算为4
        //   console.log('window.innerWidth', window.innerWidth);
        //   console.log('2width', scale.width, 'height', scale.height);
        //   appRef.style.transform = `scale(${scale.width}, ${scale.height}) translate(-50%, -50%)`
        // }
      }
    },
    resize() {
      clearTimeout(this.drawTiming)
      this.drawTiming = setTimeout(() => {
        this.calcRate()
      }, 200)
    }
  },
}